body {
  font-family: "Raleway", sans-serif;
}

h1 {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  margin: 0;
}

h2 {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  margin: 0;
}

h3 {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  margin: 0;
}

h4 {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  margin: 0;
}

h5 {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  margin: 0;
}

/* Scrollbar */
html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}
body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
